<template>
    <router-link :to="{ name: 'MealDesign', params: {  id } }">M{{ id }}</router-link>
</template>
<script>
export default {
    name: 'MealDesignLink',
    props: {
        id: {}
    }
}
</script>
