<style>
.yellow {
    color: yellow;
    background-color: yellow;
}
</style>
<template>
    <v-container fluid class="">
        <v-dialog v-model="showImageDialog">
            <v-card>
                <v-card-title>

                </v-card-title>
                <v-card-text>
                    <a :href="imageUrl">{{ imageUrl }}</a>
                    <v-img max-height="800" contain :src="imageUrl"/>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card flat v-if="!isLoading && !pendingResult && mealsWithNameMatch.length===0">
            <v-card-title>No matches</v-card-title>
        </v-card>

        <v-card class="mb-2">
            <v-card-title>{{ selectedSku }} :: {{ referenceMeal.name }}</v-card-title>
            <v-card-text>reference version is
                <meal-design-link :id="referenceMeal.id"/>
            </v-card-text>
            <v-card-text>
                Photos
                <v-alert type="info" v-if="Object.keys(referenceMeal.images||{}).length===0">no photos </v-alert>
                <v-row no-gutters style="min-height: 400px">
                    <v-col v-for="(image,index) of referenceMeal.images"
                           v-bind:key="index"
                           @click="showImageDialog=true; imageUrl=image.url"
                    >
                        {{ index }}
                        <v-img max-width="400" max-height="400"
                               :src="image.url"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn outlined :to="{name:'MealImages', params: { id: referenceMeal.id}}">Manage Images</v-btn>
            </v-card-actions>
        </v-card>
        <v-card flat v-if="mealsWithNameMatch.length>0">
            <v-card-text>
                {{ count }} versions of {{ selectedSku }} exist. Click on the star icon to change the reference version.
                Click on the camera icon to change the reference marketing photos.
            </v-card-text>
            <v-card-text>
                <!--        {{ meal.date }}-->
                <v-simple-table dense>
                    <thead>
                    <tr>
                        <th>
                            <v-checkbox
                                v-model="selectAllValue"
                                @click="selectAll"
                            />
                        </th>
                        <th>Diet</th>
                        <th>Date and Version</th>
                        <th>Images</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(meal,mealIndex) of mealsWithNameMatch" v-bind:key="meal.id">
                        <td>
                            <v-checkbox
                                v-model="selected[mealIndex]"
                            />
                        </td>
                        <td>
                            <v-chip text-color="white" small :color="getDietColor(meal.diet)">
                                {{ getDietName(meal.diet) }}
                            </v-chip>
                        </td>
                        <td>

                            <v-btn icon shaped :class="{ yellow : meal.is_selected}" @click="setSelected(meal)">
                                <v-icon>mdi-star</v-icon>
                            </v-btn>
                            {{ meal.date || 'no date set' }} - <MealDesignLink :id="meal.id"/>
                        </td>
                        <td>
                            <v-list v-for="(image,index) of meal.images" v-bind:key="index">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-btn v-if="Object.keys(meal.images||{}).length" icon shaped
                                               :class="{ yellow : isImageSelected(index,image.url)}"
                                               @click="setSelectedImage(index,image.url)">
                                            <v-icon>mdi-star</v-icon>
                                        </v-btn>
                                    </v-list-item-avatar>

                                    <v-list-item-content @click="showImageDialog=true; imageUrl=image.url">
                                        {{ index }}
                                    </v-list-item-content>
                                    <v-list-item-avatar @click="showImageDialog=true; imageUrl=image.url">
                                        <v-img max-width="100" max-height="100"
                                               :src="image.url"
                                        />
                                    </v-list-item-avatar>

                                </v-list-item>
                            </v-list>
                        </td>
                      <td>
                        <v-btn outlined :to="{name:'MealImages', params: { id: meal.id}}">Manage Images</v-btn>
                      </td>

                    </tr>
                    </tbody>
                </v-simple-table>
                <v-btn v-if="mealsWithNameMatch.length > 0 && mealsWithNameMatch.length<count"
                       class="ml-8"
                       x-small
                       :disabled="mealsWithNameMatch.length===count"
                       @click="fetchMore"
                       :loading="isLoading"
                >
                    Fetch More
                </v-btn>
            </v-card-text>
        </v-card>
    </v-container>

</template>

<script>
import api from '@/api';
import {mapActions, mapGetters, mapState} from 'vuex';

import urlState from '@/router/urlState';
import MealDesignLink from '@/views/MealDesignLink';
import {debounce} from 'lodash';
// note this is defined as a constant outside as it is used when component is constructed
const debounceDelay = 500;

export default {
    name: "MealVersions",
    components: {MealDesignLink},
    mixins: [urlState],
    data() {
        return {
            meal: null,
            mealsWithNameMatch: [],
            dates: null,
            selectedDiet: null,
            count: 0,
            from: 0,
            limit: 100,
            searchPayload: null,
            pendingResult: null,
            searchMealName: null,
            noDateSet: null,
            selected: [],
            selectAllValue: false,
            sku: null,
            showImageDialog: null,
            imageUrl: null,
            hasDate: true
        }
    },
    created() {
        return Promise.all([
            this.fetchDiets(),
            this.fetchSkus()
        ]);
    },
    mounted() {
        return this.executeSearch();
    },
    watch: {
        searchMealName: 'executeSearch',
        dates: 'executeSearch',
        selectedDiet: 'executeSearch',
        hasDate: 'executeSearch',
        selectedSku() {
            // if (sku) {
            //   this.searchMealName = undefined;
            //   this.dates = undefined;
            //   this.selectedDiet = undefined;
            //   this.hasDate = false;
            // }
            return this.executeSearch();
        }
    },
    methods: {
        ...mapActions(['fetchDiets', 'fetchSkus']),
        createSearchPayload() {
            const where = {};
            if (this.hasDate) {
                where.date = {
                    not: null
                }
            }
            if (this.searchMealName) {
                where.name = {
                    contains: this.searchMealName.split(' ').map(s => s.trim()).filter(s => !!s).join(' '),
                    mode: 'insensitive',
                }
            }
            if (this.selectedDiet) {
                where.diet = this.selectedDiet;
            }
            if (this.selectedSku) {
                where.sku = this.selectedSku;
            }

            const dates = this.dates;
            if (dates && dates.length > 0) {
                const from = dates[0];
                const to = dates[1] || from
                where.date = {
                    gte: `${from}T00:00:00.000Z`,
                    lte: `${to}T23:59:59.000Z`
                }
            }
            const searchPayload = {
                params: {
                    from: this.from || 0,
                    limit: this.limit,
                    // limit: 100,
                    // sortBy,
                    // sortDesc,
                    orderBy: {date: 'desc'},
                    where: where,
                    // minimal: true
                }
            };
            return searchPayload;
        },
        executeSearch: debounce(function ({append} = {append: false}) {
            if (!append) {
                this.from = 0;
            }
            const currentSearch = this.createSearchPayload();
            if (this.pendingResult) {
                if (JSON.stringify(currentSearch) !== JSON.stringify(this.searchPayload)) {
                    this.nextSearch = currentSearch;
                }
                return this.pendingResult;
            }
            this.searchPayload = currentSearch;
            console.log('search', currentSearch);
            this.pendingResult = api.get(`/v2/meal`, this.searchPayload)
                .then(r => {
                    const {data: {meals, count}} = r;
                    console.log('result', {count, meals});
                    if (this.from === 0) {
                        this.mealsWithNameMatch = meals;
                    } else {
                        this.mealsWithNameMatch.push(...meals);
                    }
                    this.count = count;
                })
                .finally(() => {
                    this.pendingResult = false;
                    this.searchPayload = false;
                    if (this.nextSearch) {
                        this.nextSearch = false;
                        return this.executeSearch();
                    }
                });
            return this.pendingResult;
        }, debounceDelay),
        fetchMore() {
            this.from = this.mealsWithNameMatch.length;
            this.executeSearch({append: true});
        }
        ,
        selectAll(e) {
            console.log('e', e);
            this.mealsWithNameMatch
                .forEach((v, index) =>
                    this.$set(this.selected, index, this.selectAllValue)
                );
        }
        ,
        saveSku(meal) {
            const {id} = meal;
            return api.put(`v2/meal/${id}`,
                {
                    meal: {
                        sku: meal.sku
                    }
                });
        }
        ,
        saveSkuChanges() {
            return this.selectedMeals
                .map(m => {
                    const {id} = m;
                    if (m.sku !== this.sku) {
                        return api.put(`v2/meal/${id}`,
                            {
                                meal: {
                                    sku: this.sku
                                }
                            });
                    }
                });
        },
        setSelectedImage(index, url) {
            const id = this.referenceMeal.id;
            const images = {};
            images[index] = url;
            return api
                .put(`v2/image/meal/${id}/url`, images)
                .then(r => console.log('result ', r))
                .then(() => this.executeSearch())
                .catch(e => {
                    console.error('failed to set images', e);
                });
        },
        setSelected(meal) {
            if (meal.is_selected) {
                alert('meal is already the selected version for this SKU')
            } else {
                api.put(`v2/meal/sku/${meal.sku}`, {selected_meal_id: meal.id})
                    .then(({data}) => {
                        console.log('done', data);
                        this.executeSearch();
                        // const oldMealId = data.oldValue.selected_meal_id;
                        // const oldMeal = this.mealsWithNameMatch.find(m => m.id === oldMealId)
                        // oldMeal.is_selected = false;
                        // const newMealId = data.newValue.selected_meal_id;
                        // if (meal.id === newMealId) {
                        //     meal.is_selected = true;
                        // }

                    })
                    .catch(e => console.error('fail', e))
            }
        }
        ,
        confirmCreateSku() {
            if (confirm('would you like to create a new SKU: ' + this.sku)) {
                alert('ok');
            }
        },
        isImageSelected(index, url) {
            const m = this.referenceMeal;
            return m.images && m.images[index] === url;
        }
    },
    computed: {
        ...mapState(['diets', 'skus']),
        ...mapGetters(['getDietColor', 'getDietName']),
        selectedSku() {
            return this.$route.params.sku;
        },
        isLoading() {
            return !!(this.pendingResult || this.nextSearch);
        },
        selectedMeals() {
            return this.mealsWithNameMatch
                .filter((m, index) => this.selected[index]);
        },
        referenceMeal() {
            return this.mealsWithNameMatch.find(m => m.is_selected) || {};
        }
    }

}
</script>

